import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import EnterPage from './components/home page/EnterPage';
import MyCloset from './components/my closet page/MyCloset';
import LookBook from './components/look book page/LookBook';
import SignUpLogInPage from './components/sign up_log in/SignUpLogIn';
import UploadClothes from './components/upload clothes page/UploadClothes';
import 'semantic-ui-css/semantic.min.css'

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<EnterPage />} />
          <Route exact path="/my-closet" element={<MyCloset />} />
          <Route exact path="/guest-closet" element={<MyCloset guest={true} />} />
          <Route exact path="/lookbook" element={<LookBook />} />
          <Route exact path="/login-signup" element={<SignUpLogInPage />} />
          <Route exact path="/upload-clothes" element={<UploadClothes />} />
          <Route exact path="/edit-clothes" element={<UploadClothes edit={true}/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
