import React, { useState, useEffect } from 'react';
import { Button, Form, Icon, Segment, Header, Message } from 'semantic-ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './UploadClothes.css';

function UploadClothes() {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [clothesName, setClothesName] = useState('');
  const [size, setSize] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [isGuest, setIsGuest] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if we're in guest mode
    const params = new URLSearchParams(location.search);
    const guestMode = params.get('guest') === 'true';
    setIsGuest(guestMode);

    // Check if we're in edit mode
    if (location.pathname === '/edit-clothes') {
      setIsEditMode(true);
      const id = params.get('id');
      setItemId(id);
      
      if (guestMode) {
        // Get item from localStorage for guest mode
        const guestClothes = JSON.parse(localStorage.getItem('guestClothes') || '[]');
        const item = guestClothes.find(item => item.id.toString() === id);
        if (item) {
          setClothesName(item.clothesName);
          setSize(item.size || '');
          setPreview(item.image);
        }
      } else {
        // Fetch from backend for logged-in users
        const token = localStorage.getItem('token');
        axios.get(`https://sheyou-backend.herokuapp.com/closet-clothes/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            setClothesName(res.data.clothesName);
            setSize(res.data.size || '');
            setPreview(res.data.image);
          })
          .catch(err => {
            console.error('Error fetching item:', err);
            setError('Failed to load item details');
          });
      }
    }
  }, [location]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleGuestSubmit = async () => {
    if ((!file && !isEditMode) || !clothesName) {
      setError('Please provide both an image and a name for your clothing item');
      return;
    }

    const guestClothes = JSON.parse(localStorage.getItem('guestClothes') || '[]');

    if (isEditMode) {
      // Update existing item in localStorage
      const updatedClothes = guestClothes.map(item => {
        if (item.id.toString() === itemId) {
          return {
            ...item,
            clothesName,
            size,
            image: preview
          };
        }
        return item;
      });
      localStorage.setItem('guestClothes', JSON.stringify(updatedClothes));
    } else {
      // Add new item to localStorage
      const newItem = {
        id: Date.now(),
        clothesName,
        size,
        image: preview
      };
      guestClothes.push(newItem);
      localStorage.setItem('guestClothes', JSON.stringify(guestClothes));
    }

    navigate('/guest-closet');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (isGuest) {
      await handleGuestSubmit();
      return;
    }

    if ((!file && !isEditMode) || !clothesName) {
      setError('Please provide both an image and a name for your clothing item');
      setLoading(false);
      return;
    }

    try {
      const user = localStorage.getItem('user');
      const token = localStorage.getItem('token');
      let imageUrl = preview;

      // Only upload new image if file is selected
      if (file) {
        const formData = new FormData();
        formData.append('files', file);
        const uploadRes = await axios.post('https://sheyou-backend.herokuapp.com/upload', formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
        imageUrl = uploadRes.data[0].url;
      }

      if (isEditMode) {
        // Update existing item
        await axios.put(`https://sheyou-backend.herokuapp.com/closet-clothes/${itemId}`, {
          clothesName,
          size,
          ...(file && { image: imageUrl }),
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      } else {
        // Create new item
        await axios.post('https://sheyou-backend.herokuapp.com/closet-clothes', {
          clothesName,
          size,
          image: imageUrl,
          users_permissions_users: user
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }

      navigate('/my-closet');
    } catch (err) {
      setError(`Failed to ${isEditMode ? 'update' : 'upload'} clothing item. Please try again.`);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      setLoading(true);
      
      if (isGuest) {
        // Delete from localStorage for guest mode
        const guestClothes = JSON.parse(localStorage.getItem('guestClothes') || '[]');
        const updatedClothes = guestClothes.filter(item => item.id.toString() !== itemId);
        localStorage.setItem('guestClothes', JSON.stringify(updatedClothes));
        navigate('/guest-closet');
      } else {
        // Delete from backend for logged-in users
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`https://sheyou-backend.herokuapp.com/closet-clothes/${itemId}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          navigate('/my-closet');
        } catch (err) {
          setError('Failed to delete item. Please try again.');
          console.error('Delete error:', err);
          setLoading(false);
        }
      }
    }
  };

  return (
    <div className="upload-clothes-container">
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 20px 0 20px'
      }}>
        <Icon 
          name="arrow left" 
          size="large" 
          className="back-button"
          onClick={() => navigate(isGuest ? '/guest-closet' : '/my-closet')}
        />
        {isEditMode && (
          <Button
            color="red"
            onClick={handleDelete}
            disabled={loading}
          >
            <Icon name="trash" /> Delete Item
          </Button>
        )}
      </div>
      
      <Segment className="upload-segment">
        <Header as='h2' color='teal' textAlign='center'>
          {isEditMode ? 'Edit Clothing Item' : 'Upload New Clothing Item'}
        </Header>
        <Form onSubmit={handleSubmit} loading={loading}>
          <div className="upload-preview-container">
            {preview ? (
              <img 
                src={preview} 
                alt="Preview" 
                className="upload-preview"
              />
            ) : (
              <div className="upload-placeholder">
                <Icon name="image outline" size="huge" color="grey" />
                <p>Preview will appear here</p>
              </div>
            )}
          </div>
          
          <Button
            as="label"
            htmlFor="file-input"
            type="button"
            fluid
            color="yellow"
            className="upload-button"
          >
            <Icon name='upload' /> {isEditMode ? 'Change Image' : 'Choose Image'}
          </Button>
          <input
            id="file-input"
            type="file"
            hidden
            onChange={handleFileChange}
            accept="image/*"
          />

          <Form.Input
            fluid
            placeholder='Name your item'
            value={clothesName}
            onChange={(e) => setClothesName(e.target.value)}
          />
          
          <Form.Input
            fluid
            placeholder='Size (optional)'
            value={size}
            onChange={(e) => setSize(e.target.value)}
          />

          {error && (
            <Message negative>
              <p>{error}</p>
            </Message>
          )}

          <Button 
            type='submit' 
            fluid 
            color='teal'
            disabled={(!file && !isEditMode) || !clothesName || loading}
          >
            {isEditMode ? 'Save Changes' : 'Upload to My Closet'}
          </Button>
        </Form>
      </Segment>
    </div>
  );
}

export default UploadClothes; 