import React, { useState, useEffect } from 'react';
import { Button, Grid, Segment, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import './EnterPage.css';

//LIVE
// const baseUrl = 'https://sheyoufashion.com';

//LOCAL
const baseUrl = 'http://localhost:3001';

function EnterPage() {
  const navigate = useNavigate();
  const [weather, setWeather] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [location, setLocation] = useState('Loading location...');
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Get user's location
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          
          // First, get city name from coordinates
          try {
            const geoResponse = await fetch(
              `https://api.openweathermap.org/geo/1.0/reverse?lat=${latitude}&lon=${longitude}&limit=1&appid=ad909fca09355978ceecd996d11f8eec`
            );
            const geoData = await geoResponse.json();
            const cityName = geoData[0].name;
            setLocation(cityName);

            // Then fetch weather data using coordinates for more accuracy
            const weatherResponse = await fetch(
              `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&appid=ad909fca09355978ceecd996d11f8eec`
            );
            const weatherData = await weatherResponse.json();
            setWeather(weatherData);
          } catch (err) {
            setError("Error fetching weather data");
            console.error('Error:', err);
          }
        },
        (err) => {
          setError("Please enable location access to see local weather");
          console.error("Error getting location:", err);
        }
      );
    } else {
      setError("Geolocation is not supported by your browser");
    }

    console.log('Full URL:', window.location.href);
    console.log('Hash:', window.location.hash);

    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(2));

    const user = params.get('user');
    const token = params.get('token');
    const success = params.get('success');

    console.log('URL Parameters:', {
      user,
      token,
      success
    });

    if (user && token && success) {
      console.log('Storing credentials for user:', user);
      localStorage.setItem('user', user);
      localStorage.setItem('token', token);
      localStorage.setItem('success', success);
      
      if (success === 'true') {
        setShowSuccess(true);
        // Hide the message after 5 seconds and navigate
        setTimeout(() => {
          setShowSuccess(false);
          navigate('/my-closet');
        }, 3000);
      }
    }

    // Check login status from localStorage
    const ifSuccess = localStorage.getItem('success');
    setIsLoggedIn(ifSuccess === 'true');
  }, [navigate]);

  const handleEnterClick = () => {
    navigate('/guest-closet');
  };

  return (
    <div className="enter-page">
      <h1 className="welcome-text">Welcome to SUSU Closet</h1>
      <Segment style={{ backgroundColor: '#f5f5dd', margin: '20px 0', padding: '20px' }}>
        <h2>{location}</h2>
        {error ? (
          <p style={{ color: 'red' }}>{error}</p>
        ) : weather ? (
          <p>Today's weather: {weather.main.temp}°C, {weather.weather[0].description}</p>
        ) : (
          <p>Loading weather information...</p>
        )}
      </Segment>
      {showSuccess && (
        <Grid centered>
          <Segment className="success-message" style={{ backgroundColor: '#f5f5dd', padding: '15px' }}>
            <p style={{ color: 'green', margin: 0 }}>You have successfully logged in!</p>
            <Icon loading color='yellow' name='asterisk' />
          </Segment>
        </Grid>
      )}
      
      {isLoggedIn ? (
        <Button 
          size="huge" 
          className="enter-button" 
          color="teal" 
          onClick={() => navigate('/my-closet')}
        >
          Enter Your Closet
        </Button>
      ) : (
        <>
          <Button 
            size="huge" 
            className="enter-button" 
            color="teal" 
            onClick={handleEnterClick}
          >
            Enter as Guest
          </Button>
          <Button 
            size="huge" 
            className="login-button" 
            color="yellow" 
            onClick={() => window.location.href = `${baseUrl}/signin?isClosetUser=true`}
          >
            Sign Up / Log In
          </Button>
        </>
      )}
    </div>
  );
}

export default EnterPage;
