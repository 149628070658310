import React from 'react'
import {
  Segment,
  Grid,
  Header,
  Divider,
  Container,
  Form,
  Icon,
  TransitionablePortal
} from 'semantic-ui-react'
// import {Link} from 'react-router-dom'
import axios from 'axios'
// import { withRouter } from 'react-router-dom'
// import axios from 'axios'
const userLang = navigator.language || navigator.userLanguage

class Foot extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      mail: ''
    }
  }

  componentDidMount () {}

  handleSubscription () {
    const self = this
    axios('https://sheyou-backend.herokuapp.com/maillists', {
      method: 'post',
      data: {
        mail: self.state.mail
      }
    })
      .then(function (res) {
        console.log(res)
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  onHandleMail (event) {
    this.setState({
      mail: event.target.value
    })
  }

  handleOpen = () => this.setState({ open: true })

  handleClose = () => this.setState({ open: false })
  render () {
    const self = this
    return (
      <>
        <Segment
          placeholder
          style={{
            marginTop: 0,
            paddingBottom: 0,
            height: window.innerWidth < 766 ? 600 : 400
          }}
        >
          <Container style={{ marginTop: 0, paddingBottom: 0 }}>
            <Grid
              stackable
              columns={2}
              divided={window.innerWidth < 766 ? false : true}
              centered
              style={{ height: window.innerWidth < 766 ? 80 : 100 }}
            >
              <Grid.Column>
                <Grid centered>
                  <Grid.Row
                  // style={
                  //   {
                  //     paddingBottom: 5,
                  //     // paddingTop: 25
                  //   }
                  // }
                  >
                    <Header>
                      {userLang === 'zh-CN' ? '最新资讯' : 'Know It All First'}
                    </Header>
                  </Grid.Row>
                  <Grid.Row>
                    <Header as='h5' color='grey'>
                      {userLang === 'zh-CN'
                        ? '不想错过有关SHEYOU的任何资讯？订阅加入我们的 mailing list!'
                        : 'Never Miss Anything From SHEYOU By Subscribing.'}
                    </Header>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column textAlign='center'>
                <Grid centered>
                  <Form style={{paddingTop: window.innerWidth < 766 ? 20 : 30}}>
                    <Form.Group >
                      <Form.Input
                        fluid
                        style={{width:180}}
                        placeholder={
                          userLang === 'zh-CN' ? '邮箱地址' : 'email'
                        }
                      />
                      <TransitionablePortal
                        closeOnTriggerClick
                        onOpen={this.handleOpen}
                        onClose={this.handleClose}
                        openOnTriggerClick
                        trigger={
                          <Form.Button
                            color='teal'
                            onClick={self.handleSubscription}
                          >
                            {userLang === 'zh-CN' ? '订阅' : 'Subscribe'}
                          </Form.Button>
                        }
                      >
                        <Segment>
                          <Header>
                            {userLang === 'zh-CN'
                              ? '成功订阅'
                              : 'We Have Received Your Subscription'}
                            <Icon name='smile outline' />
                          </Header>
                        </Segment>
                      </TransitionablePortal>
                    </Form.Group>
                  </Form>
                </Grid>
              </Grid.Column>
            </Grid>
          </Container>

          <Divider
            style={{
              position: 'relative',
              top: window.innerWidth < 766 ? 130 : 15
            }}
          />
          <br />
          <br />

          <Grid
            stackable
            centered
            style={{
              position: 'relative',
              top: window.innerWidth < 766 ? 100 : 10,
              paddingBottom: window.innerWidth < 766 ? 50 : 10
            }}
            // divided={window.innerWidth < 766 ? true : false}
          >
            
              <Grid.Column
                width={9}
                textAlign='center'
                // style={{ paddingRight: window.innerWidth < 766 ? 45 : 0 }}
              >
                <Grid centered>
                  <Grid.Row>
                    <Header color='teal' name='Home' style={{ fontSize: 40 }}>
                      SHEYOU
                      <Header.Subheader style={{ color: '#767676' }}>
                        {userLang === 'zh-CN'
                          ? '新时代的时尚平台'
                          : 'Next Generation Fashion Platform '}
                      </Header.Subheader>
                    </Header>
                  </Grid.Row>
                  <Grid.Row>
                    {userLang === 'zh-CN'
                      ? 'Copyright @四川舍予人玉商贸有限公司'
                      : 'Copyright @Sichuan Sheyu Renyu Trading Co. LTD'}
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column
                width={7}
                textAlign={window.innerWidth < 766 ? 'center' : null}
                // style={{ paddingLeft: window.innerWidth < 766 ? 45 : 100 }}
              >
                <Header>
                  {userLang === 'zh-CN' ? '联系我们' : 'Contact Us'}
                </Header>
                <p>
                  {userLang === 'zh-CN'
                    ? '手机: 17308079166'
                    : 'Phone Number: (360)202-2983'}
                </p>
                <p>
                  {userLang === 'zh-CN'
                    ? 'E-mail: 1181456762@qq.com'
                    : 'E-mail: susanna.xinshu@gmail.com'}
                </p>
              </Grid.Column>
            
          </Grid>

          <br />
          <br />
          <br />
          <br />
        </Segment>
      </>
    )
  }
}

export default Foot
