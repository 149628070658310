import React, { useState, useRef, useEffect } from 'react';
import { Card, Segment, Icon, Button, Popup, Form, Input } from 'semantic-ui-react';
import './MyCloset.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import EmptyClosetCard from '../EmptyClosetCard';
import Moveable from "react-moveable";
import { ChromePicker } from 'react-color';

const API_URL = 'https://sheyou-backend.herokuapp.com';

function MyCloset({ guest }) {
  const [clothingItems, setClothingItems] = useState([]);
  const [droppedItems, setDroppedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [placeholderWidth, setPlaceholderWidth] = useState(0);
  const [placeholderHeight, setPlaceholderHeight] = useState(0);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isMoving, setIsMoving] = useState(false);
  const [moveStartPos, setMoveStartPos] = useState({ x: 0, y: 0 });
  const [isResizing, setIsResizing] = useState(false);
  const [resizeStartPos, setResizeStartPos] = useState({ x: 0, y: 0 });
  const [resizeStartDimensions, setResizeStartDimensions] = useState({ width: 0, height: 0 });
  const navigate = useNavigate();
  const [isLookPopupOpen, setIsLookPopupOpen] = useState(false);
  const [lookName, setLookName] = useState('');
  const [isNoItemsPopupOpen, setIsNoItemsPopupOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const folderIconRef = useRef(null);
  const placeholderRef = useRef(null);
  const [userAvatar, setUserAvatar] = useState(null);
  const fileInputRef = useRef(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [rotateStartAngle, setRotateStartAngle] = useState(0);
  const [highestZIndex, setHighestZIndex] = useState(1);
  const [avatarWidth, setAvatarWidth] = useState(50); // Default 50% of container width
  const [avatarHeight, setAvatarHeight] = useState(70); // Default 70% of container height
  const [skinTone, setSkinTone] = useState(50); // 0-100 scale for skin tone
  const [customColor, setCustomColor] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showAvatarControls, setShowAvatarControls] = useState(false);

  useEffect(() => {
    if (placeholderRef.current) {
      setPlaceholderWidth(placeholderRef.current.offsetWidth);
      setPlaceholderHeight(placeholderRef.current.offsetHeight);
      
      // Load current look after setting dimensions
      const currentLook = localStorage.getItem('currentLook');
      if (currentLook) {
        const parsedLook = JSON.parse(currentLook);
        setDroppedItems(parsedLook);
      }
    }

    if (guest) {
      // Get clothes from localStorage for guest mode
      const guestClothes = JSON.parse(localStorage.getItem('guestClothes') || '[]');
      setClothingItems(guestClothes);
    } else {
      // Original database fetching code
      const user = localStorage.getItem('user');
      const token = localStorage.getItem('token');
      
      axios(`https://sheyou-backend.herokuapp.com/closet-clothes?users_permissions_users=${user}`, {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(res => {
          if (res.status !== 200) {
            localStorage.clear();
            navigate('/');
            return;
          }
          console.log(res.data);
          setClothingItems(res.data);
        })
        .catch(error => {
          console.error('Error fetching clothing items:', error);
          localStorage.clear();
          navigate('/');
        });
    }
  }, [navigate, guest]);

  useEffect(() => {
    if (droppedItems.length > 0) {
      const look = droppedItems.map(item => ({
        id: item.id,
        image: item.image,
        x: item.x,
        y: item.y,
        width: item.width,
        height: item.height
      }));
      localStorage.setItem('currentLook', JSON.stringify(look));
    } else {
      localStorage.removeItem('currentLook'); // Clear currentLook if no items
    }
  }, [droppedItems]);

  const handleDragStart = (e, item) => {
    const rect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    setDragOffset({ x: offsetX, y: offsetY });
    e.dataTransfer.setData('text/plain', JSON.stringify(item));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const item = JSON.parse(e.dataTransfer.getData('text/plain'));
    const rect = placeholderRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left - dragOffset.x;
    const y = e.clientY - rect.top - dragOffset.y;
    
    const img = new Image();
    img.src = item.image;
    img.onload = () => {
      const aspectRatio = img.height / img.width;
      const newWidth = placeholderWidth / 1.5;
      const newHeight = newWidth * aspectRatio;
      
      // Calculate new highest z-index
      const newZIndex = Math.max(...droppedItems.map(item => item.zIndex || 0)) + 1;
      
      const newItem = { 
        ...item, 
        x, 
        y, 
        width: newWidth, 
        height: newHeight,
        rotation: 0,
        zIndex: newZIndex
      };
      setDroppedItems(prevItems => [...prevItems, newItem]);
      setSelectedItem(droppedItems.length);
    };
  };

  const handleItemClick = (e, index) => {
    e.stopPropagation();
    
    // Always update z-index when clicking an item
    const newZIndex = Math.max(...droppedItems.map(item => item.zIndex || 0)) + 1;
    
    setSelectedItem(index);
    setDroppedItems(prevItems => 
      prevItems.map((item, i) => ({
        ...item,
        zIndex: i === index ? newZIndex : (item.zIndex || 1)
      }))
    );
  };

  const handleMoveStart = (e, index) => {
    e.stopPropagation();
    setIsMoving(true);
    setSelectedItem(index);
    const rect = placeholderRef.current.getBoundingClientRect();
    const item = droppedItems[index];
    setDragOffset({
      x: e.clientX - rect.left - item.x,
      y: e.clientY - rect.top - item.y
    });
  };

  const handleMove = (e) => {
    if (isMoving && selectedItem !== null) {
      const rect = placeholderRef.current.getBoundingClientRect();
      const newX = e.clientX - rect.left;
      const newY = e.clientY - rect.top;
      
      setDroppedItems(prevItems => 
        prevItems.map((item, index) => 
          index === selectedItem
            ? { 
                ...item, 
                x: newX - dragOffset.x,
                y: newY - dragOffset.y
              }
            : item
        )
      );
    }
  };

  const handleMoveEnd = () => {
    setIsMoving(false);
  };

  const handleResizeStart = (e, index, resizeType) => {
    e.stopPropagation();
    setIsResizing(true);
    setSelectedItem(index);
    setResizeStartPos({ x: e.clientX, y: e.clientY });
    setResizeStartDimensions({
      width: droppedItems[index].width,
      height: droppedItems[index].height,
      type: resizeType
    });
    
    // Update all images to use appropriate object-fit
    setDroppedItems(prevItems =>
      prevItems.map((item, i) => ({
        ...item,
        isProportional: i === index ? resizeType === 'proportional' : item.isProportional
      }))
    );
  };

  const handleResize = (e) => {
    if (isResizing && selectedItem !== null) {
      const deltaX = e.clientX - resizeStartPos.x;
      const deltaY = e.clientY - resizeStartPos.y;
      const aspectRatio = resizeStartDimensions.height / resizeStartDimensions.width;
      
      let newWidth = resizeStartDimensions.width;
      let newHeight = resizeStartDimensions.height;

      switch (resizeStartDimensions.type) {
        case 'width':
          newWidth = Math.max(50, resizeStartDimensions.width + deltaX);
          break;
        case 'height':
          newHeight = Math.max(50, resizeStartDimensions.height + deltaY);
          break;
        case 'proportional':
          newWidth = Math.max(50, resizeStartDimensions.width + deltaX);
          newHeight = newWidth * aspectRatio;
          break;
        default:
          break;
      }

      setDroppedItems(prevItems =>
        prevItems.map((item, index) =>
          index === selectedItem
            ? {
                ...item,
                width: newWidth,
                height: newHeight
              }
            : item
        )
      );
    }
  };

  const handleResizeEnd = () => {
    setIsResizing(false);
  };

  const handleRotateStart = (e, index) => {
    e.stopPropagation();
    setIsRotating(true);
    setSelectedItem(index);
    
    const item = droppedItems[index];
    const itemRect = e.target.parentElement.getBoundingClientRect();
    const itemCenterX = itemRect.left + itemRect.width / 2;
    const itemCenterY = itemRect.top + itemRect.height / 2;
    
    // Calculate initial angle
    const startAngle = Math.atan2(
      e.clientY - itemCenterY,
      e.clientX - itemCenterX
    ) * (180 / Math.PI);
    
    setRotateStartAngle(startAngle - (item.rotation || 0));
  };

  const handleRotate = (e) => {
    if (isRotating && selectedItem !== null) {
      const item = droppedItems[selectedItem];
      const itemRect = placeholderRef.current.querySelector(
        `.dragged-item-container:nth-child(${selectedItem + 1})`
      ).getBoundingClientRect();
      
      const itemCenterX = itemRect.left + itemRect.width / 2;
      const itemCenterY = itemRect.top + itemRect.height / 2;
      
      // Calculate current angle
      const currentAngle = Math.atan2(
        e.clientY - itemCenterY,
        e.clientX - itemCenterX
      ) * (180 / Math.PI);
      
      // Calculate rotation
      let rotation = currentAngle - rotateStartAngle;
      
      // Snap to 45-degree intervals when holding Shift
      if (e.shiftKey) {
        rotation = Math.round(rotation / 45) * 45;
      }
      
      setDroppedItems(prevItems =>
        prevItems.map((item, index) =>
          index === selectedItem
            ? { ...item, rotation }
            : item
        )
      );
    }
  };

  const handleRotateEnd = () => {
    setIsRotating(false);
  };

  useEffect(() => {
    if (isMoving) {
      window.addEventListener('mousemove', handleMove);
      window.addEventListener('mouseup', handleMoveEnd);
    }
    if (isResizing) {
      window.addEventListener('mousemove', handleResize);
      window.addEventListener('mouseup', handleResizeEnd);
    }
    if (isRotating) {
      window.addEventListener('mousemove', handleRotate);
      window.addEventListener('mouseup', handleRotateEnd);
    }
    return () => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleMoveEnd);
      window.removeEventListener('mousemove', handleResize);
      window.removeEventListener('mouseup', handleResizeEnd);
      window.removeEventListener('mousemove', handleRotate);
      window.removeEventListener('mouseup', handleRotateEnd);
    };
  }, [isMoving, isResizing, isRotating, selectedItem]);

  const handleCardClick = (item) => {
    const img = new Image();
    img.src = item.image;
    img.onload = () => {
      const aspectRatio = img.height / img.width;
      const newWidth = placeholderWidth/1.5;
      const newHeight = newWidth * aspectRatio;
      const x = (placeholderWidth - newWidth) / 2;
      const y = (placeholderHeight - newHeight) / 2;
      setDroppedItems([...droppedItems, { ...item, x, y, width: newWidth, height: newHeight }]);
    };
  };

  const handleCreateLook = () => {
    if (droppedItems.length === 0) {
      setIsNoItemsPopupOpen(true);
      setTimeout(() => setIsNoItemsPopupOpen(false), 3000); // Close after 3 seconds
    } else {
      setIsLookPopupOpen(true);
    }
  };

  const saveLook = async () => {
    const look = droppedItems.map(item => ({
      id: item.id,
      image: item.image,
      x: item.x,
      y: item.y,
      width: item.width,
      height: item.height
    }));

    // Save to localStorage
    const existingLooks = JSON.parse(localStorage.getItem('savedLooks') || '[]');
    const newLook = {
      id: Date.now(),
      name: lookName,
      items: look
    };
    existingLooks.push(newLook);
    localStorage.setItem('savedLooks', JSON.stringify(existingLooks));

    // If user is logged in, save to backend
    if (!guest) {
      try {
        const token = localStorage.getItem('token');
        const user = localStorage.getItem('user');
        
        await axios(`${API_URL}/closet-looks`, {
          method: 'post',
          data: {
            lookName: lookName,
            lookData: look,
            closet_clothes: droppedItems.map(item => item.id),
            users_permissions_user: user
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      } catch (error) {
        console.error('Error saving look to backend:', error);
      }
    }

    setIsLookPopupOpen(false);
    setLookName('');
    setIsSuccessPopupOpen(true);
    setTimeout(() => setIsSuccessPopupOpen(false), 3000);
  };

  const handleDeleteSelection = () => {
    if (selectedItem !== null) {
      setDroppedItems(prevItems => prevItems.filter((_, index) => index !== selectedItem));
      setSelectedItem(null);
    }
  };

  const handleHomeClick = () => {
    saveCurrentLook();
    navigate(guest ? '/' : '/');
  };

  const handleFolderClick = () => {
    saveCurrentLook();
    navigate(guest ? '/guest-lookbook' : '/lookbook');
  };

  const handleAvatarUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUserAvatar(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleEditItem = (itemId) => {
    navigate(`/edit-clothes?id=${itemId}`);
  };

  // Add function to handle guest mode uploads
  const handleGuestUpload = (newItem) => {
    const guestClothes = JSON.parse(localStorage.getItem('guestClothes') || '[]');
    const itemWithId = { ...newItem, id: Date.now() }; // Add unique ID for guest items
    guestClothes.push(itemWithId);
    localStorage.setItem('guestClothes', JSON.stringify(guestClothes));
    setClothingItems(guestClothes);
  };

  // Add function to handle guest mode deletions
  const handleGuestDelete = (itemId) => {
    const guestClothes = JSON.parse(localStorage.getItem('guestClothes') || '[]');
    const updatedClothes = guestClothes.filter(item => item.id !== itemId);
    localStorage.setItem('guestClothes', JSON.stringify(updatedClothes));
    setClothingItems(updatedClothes);
  };

  const saveCurrentLook = () => {
    if (droppedItems.length > 0) {
      const look = droppedItems.map(item => ({
        id: item.id,
        image: item.image,
        x: item.x,
        y: item.y,
        width: item.width,
        height: item.height
      }));
      localStorage.setItem('currentLook', JSON.stringify(look));
    } else {
      localStorage.removeItem('currentLook');
    }
  };

  const handlePlaceholderClick = (e) => {
    if (e.target.className === 'sticky-placeholder' || 
        e.target.className === 'placeholder-image' ||
        e.target.className === 'dropped-items-layer') {
      setSelectedItem(null);
    }
  };

  const getAvatarColor = () => {
    if (customColor) {
      return customColor;
    }
    
    // Define three color points for better skin tone transition
    const lightBeige = [245, 225, 208];  // Light beige
    const darkBeige = [200, 170, 140];   // Dark beige
    const darkBrown = [89, 47, 42];      // Dark brown
    
    const ratio = skinTone / 100;
    let color;
    
    if (ratio <= 0.5) {
      // Transition from light beige to dark beige
      const localRatio = ratio * 2; // Convert 0-0.5 to 0-1
      color = lightBeige.map((light, index) => {
        const mid = darkBeige[index];
        return Math.round(light + (mid - light) * localRatio);
      });
    } else {
      // Transition from dark beige to dark brown
      const localRatio = (ratio - 0.5) * 2; // Convert 0.5-1 to 0-1
      color = darkBeige.map((mid, index) => {
        const dark = darkBrown[index];
        return Math.round(mid + (dark - mid) * localRatio);
      });
    }
    
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  };

  const handleColorChange = (color) => {
    setCustomColor(color.hex);
  };

  const getColorHue = (color) => {
    // If it's already an RGB string, parse it
    if (color.startsWith('rgb')) {
      const rgb = color.match(/\d+/g).map(Number);
      return calculateHue(rgb[0], rgb[1], rgb[2]);
    }
    
    // If it's a hex color, convert to RGB first
    if (color.startsWith('#')) {
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      return calculateHue(r, g, b);
    }
    
    return '0deg'; // default
  };

  const calculateHue = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    
    let h;
    if (max === min) {
      h = 0;
    } else if (max === r) {
      h = 60 * ((g - b) / (max - min));
    } else if (max === g) {
      h = 60 * (2 + (b - r) / (max - min));
    } else {
      h = 60 * (4 + (r - g) / (max - min));
    }
    
    if (h < 0) h += 360;
    return `${h}deg`;
  };

  const handleAvatarEditToggle = () => {
    const newShowControls = !showAvatarControls;
    setShowAvatarControls(newShowControls);
    if (newShowControls) {
      // Set default skin tone when enabling edit mode
      setSkinTone(50); // Neutral flesh tone
      setCustomColor(null);
    }
  };

  const getColorValues = (color) => {
    let r, g, b;
    
    if (color.startsWith('rgb')) {
      [r, g, b] = color.match(/\d+/g).map(Number);
    } else if (color.startsWith('#')) {
      r = parseInt(color.slice(1, 3), 16);
      g = parseInt(color.slice(3, 5), 16);
      b = parseInt(color.slice(5, 7), 16);
    }

    // Convert RGB to HSL and calculate filter values
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }

    // Calculate filter values
    return {
      brightness: Math.round(l * 200), // Convert luminance to brightness
      sepia: Math.round(s * 100),      // Use saturation for sepia
      saturate: Math.round(s * 1000),  // Boost saturation
      hue: Math.round(h * 360)         // Convert to degrees
    };
  };

  return (
    <div className="my-closet">
      <div className="sticky-wrapper">
        <div className="icon-container">
          <Icon name="home" size="large" color="teal" className="home-icon" onClick={handleHomeClick} />
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleAvatarUpload}
            style={{ display: 'none' }}
            accept="image/*"
          />
          <div className="avatar-controls">
            <Button
              className="upload-avatar-button"
              onClick={() => fileInputRef.current.click()}
            >
              {userAvatar ? 'Change Image' : 'Upload Image'}
            </Button>
            
            {userAvatar ? (
              <Icon 
                name="redo" 
                size="large" 
                color="red" 
                className="reset-icon"
                onClick={() => setUserAvatar(null)}
              />
            ) : (
              <>
                <div className="vertical-divider">or</div>
                <Button
                  icon="edit"
                  circular
                  color={showAvatarControls ? 'teal' : 'grey'}
                  onClick={handleAvatarEditToggle}
                  className="edit-avatar-button"
                />
              </>
            )}
          </div>
          <Popup
            trigger={<Icon ref={folderIconRef} name="folder open" size="large" color="teal" className="folder-icon" onClick={handleFolderClick} />}
            content="Look Saved :)"
            position="top center"
            open={isSuccessPopupOpen}
            onClose={() => setIsSuccessPopupOpen(false)}
          />
        </div>
        <div 
          ref={placeholderRef}
          className="sticky-placeholder"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handlePlaceholderClick}
        >
          <img 
            src={userAvatar || "https://res.cloudinary.com/sheyou/image/upload/v1730947058/placeholder_4931f218c9.png"}
            className={userAvatar ? "uploaded-avatar-image" : "placeholder-image"}
            alt="Placeholder"
            draggable="false"
            style={{
              width: userAvatar ? '100%' : `${avatarWidth}%`,
              filter: userAvatar ? 'none' : (
                showAvatarControls 
                  ? `brightness(0) saturate(100%) invert(${getColorValues(getAvatarColor()).brightness}%) sepia(${getColorValues(getAvatarColor()).sepia}%) saturate(${getColorValues(getAvatarColor()).saturate}%) hue-rotate(${getColorValues(getAvatarColor()).hue}deg)`
                  : 'none'
              ),
            }}
          />
          <div className="dropped-items-layer">
            {droppedItems.map((item, index) => (
              <div 
                key={`${item.id}-${index}`}
                className="dropped-item-wrapper"
              >
                <div 
                  className={`dragged-item-container ${selectedItem === index ? 'selected' : ''}`}
                  style={{ 
                    left: `${item.x}px`, 
                    top: `${item.y}px`,
                    width: `${item.width}px`,
                    height: `${item.height}px`,
                    transform: `rotate(${item.rotation || 0}deg)`,
                    zIndex: item.zIndex || 1
                  }}
                  onClick={(e) => handleItemClick(e, index)}
                  onMouseDown={(e) => handleMoveStart(e, index)}
                >
                  <img 
                    src={item.image} 
                    className={`dragged-image ${item.isProportional ? 'proportional' : ''}`}
                    alt={item.name}
                    draggable="false"
                  />
                  {selectedItem === index && (
                    <>
                      <div className="resize-handles">
                        <div 
                          className="resize-handle corner top-left" 
                          onMouseDown={(e) => handleResizeStart(e, index, 'proportional')} 
                        />
                        <div 
                          className="resize-handle corner top-right" 
                          onMouseDown={(e) => handleResizeStart(e, index, 'proportional')} 
                        />
                        <div 
                          className="resize-handle corner bottom-left" 
                          onMouseDown={(e) => handleResizeStart(e, index, 'proportional')} 
                        />
                        <div 
                          className="resize-handle corner bottom-right" 
                          onMouseDown={(e) => handleResizeStart(e, index, 'proportional')} 
                        />
                        
                        <div 
                          className="resize-handle edge left" 
                          onMouseDown={(e) => handleResizeStart(e, index, 'width')} 
                        />
                        <div 
                          className="resize-handle edge right" 
                          onMouseDown={(e) => handleResizeStart(e, index, 'width')} 
                        />
                        
                        <div 
                          className="resize-handle edge top" 
                          onMouseDown={(e) => handleResizeStart(e, index, 'height')} 
                        />
                        <div 
                          className="resize-handle edge bottom" 
                          onMouseDown={(e) => handleResizeStart(e, index, 'height')} 
                        />
                      </div>
                      <div 
                        className="rotate-handle"
                        onMouseDown={(e) => handleRotateStart(e, index)}
                      />
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {showAvatarControls && (
          <div className="avatar-controls-container">
            <div className="avatar-sliders">
              <div className="slider-container">
                <label>Body Width</label>
                <Input 
                  type="range"
                  min={40}
                  max={90}
                  value={avatarWidth}
                  onChange={(e) => setAvatarWidth(e.target.value)}
                  className="avatar-slider"
                />
                <span>{avatarWidth}%</span>
              </div>
              <div className="slider-container">
                <label>Skin Tone</label>
                <Input 
                  type="range"
                  min={0}
                  max={100}
                  value={skinTone}
                  onChange={(e) => {
                    setSkinTone(e.target.value);
                    setCustomColor(null);
                  }}
                  className="avatar-slider"
                />
                <Button 
                  icon="tint" 
                  size="mini"
                  onClick={() => setShowColorPicker(!showColorPicker)}
                  style={{ 
                    backgroundColor: customColor || getAvatarColor(),
                    width: '30px',
                    height: '30px',
                    padding: '0px'
                  }}
                />
                {showColorPicker && (
                  <div className="color-picker-popover">
                    <div 
                      className="color-picker-cover" 
                      onClick={() => setShowColorPicker(false)}
                    />
                    <ChromePicker 
                      color={customColor || getAvatarColor()}
                      onChange={handleColorChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="button-container">
          <Popup
            open={isNoItemsPopupOpen}
            content="Please add some items to create a look."
            on="click"
            position="top right"
            trigger={
              <Popup
                on='click'
                pinned
                open={isLookPopupOpen}
                onClose={() => setIsLookPopupOpen(false)}
                trigger={<Button onClick={handleCreateLook} color="teal">Create a Look</Button>}
              >
                <Form>
                  <Form.Field>
                    <label>Look Name</label>
                    <input 
                      placeholder='Enter look name' 
                      value={lookName}
                      onChange={(e) => setLookName(e.target.value)}
                    />
                  </Form.Field>
                  <Button color='green' onClick={saveLook}>Confirm</Button>
                  <Button color='red' onClick={() => setIsLookPopupOpen(false)}>Cancel</Button>
                </Form>
              </Popup>
            }
          />
          <Button onClick={handleDeleteSelection} color="red" disabled={selectedItem === null}>Delete Selection</Button>
        </div>
      </div>
      <Segment className="scrollable-cards">
        {clothingItems.length === 0 ? (
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center', 
            minHeight: '400px'
          }}>
            <EmptyClosetCard />
            <Button 
              color="yellow" 
              size="large"
              onClick={() => navigate(guest ? '/upload-clothes?guest=true' : '/upload-clothes')}
            >
              Upload Your Clothes
            </Button>
          </div>
        ) : (
          <>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px',
              marginBottom: '10px',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              zIndex: 1,
              borderBottom: '1px solid #f0f0f0'
            }}>
              <Icon 
                name="upload" 
                size="large" 
                color="yellow" 
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(guest ? '/upload-clothes?guest=true' : '/upload-clothes')}
              />
              <Icon 
                name="edit" 
                size="large" 
                color="yellow" 
                style={{ cursor: 'pointer' }}
                onClick={handleEditMode}
              />
            </div>
            <Card.Group centered>
              {clothingItems.map(item => (
                <Card 
                  key={item.id}
                  draggable={!isEditMode}
                  onDragStart={(e) => !isEditMode && handleDragStart(e, item)}
                  onClick={() => isEditMode ? handleEditItem(item.id) : handleCardClick(item)}
                >
                  <img src={item.image} alt={item.clothesName} className="card-image" />
                  <Card.Content>
                    <Card.Header>{item.clothesName}</Card.Header>
                    <Card.Meta>{item.size || 'No size specified'}</Card.Meta>
                    {isEditMode && (
                      <Icon 
                        name="edit outline" 
                        color="yellow" 
                        style={{ 
                          position: 'absolute', 
                          top: '10px', 
                          right: '10px',
                          cursor: 'pointer',
                          background: 'rgba(255,255,255,0.8)',
                          padding: '5px',
                          borderRadius: '50%'
                        }}
                      />
                    )}
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          </>
        )}
      </Segment>
    </div>
  );
}

MyCloset.defaultProps = {
  guest: false
};

export default MyCloset;