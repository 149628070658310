import React from 'react';
import { Card } from 'semantic-ui-react';

function EmptyClosetCard() {
  return (
    <Card style={{ margin: '0 auto 20px auto' }}>
      <div style={{ 
        height: '200px', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        padding: '20px'
      }}>
        <img 
          src="https://res.cloudinary.com/sheyou/image/upload/v1645859101/t_shirt_23ad28188a.png"
          alt="Sample T-shirt"
          style={{ 
            maxWidth: '80%',
            maxHeight: '80%',
            objectFit: 'contain'
          }}
        />
      </div>
      <Card.Content>
        <Card.Header textAlign="center">Sample Item</Card.Header>
        <Card.Meta textAlign="center">Upload your first item!</Card.Meta>
      </Card.Content>
    </Card>
  );
}

export default EmptyClosetCard; 