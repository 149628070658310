import React, { useState, useEffect } from 'react'
import { Icon, Card, Image, Grid } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import './LookBook.css'

function LookBook () {
  const navigate = useNavigate()
  const [savedLooks, setSavedLooks] = useState([])
  const [isEditMode, setIsEditMode] = useState(false)
  const [weeklyLooks, setWeeklyLooks] = useState({})

  useEffect(() => {
    const looks = JSON.parse(localStorage.getItem('savedLooks') || '[]')
    setSavedLooks(looks)
    const weekly = JSON.parse(localStorage.getItem('weeklyLooks') || '{}')
    setWeeklyLooks(weekly)
  }, [])

  // Remove this useEffect hook
  // useEffect(() => {
  //   const activeLooks = savedLooks.filter(look => !look.delete)
  //   localStorage.setItem('savedLooks', JSON.stringify(activeLooks))
  // }, [savedLooks])

  const handleHomeClick = () => {
    navigate('/')
  }

  const handleClosetClick = () => {
    navigate('/my-closet')
  }

  const handleEditClick = () => {
    setIsEditMode(!isEditMode)
  }

  const handleDeleteClick = (lookId) => {
    setSavedLooks(prevLooks => {
      const updatedLooks = prevLooks.filter(look => look.id !== lookId);
      localStorage.setItem('savedLooks', JSON.stringify(updatedLooks));
      return updatedLooks;
    });
  };

  // Remove this function as it's no longer needed
  // const handleDeleteLook = (lookId) => {
  //   const updatedLooks = savedLooks.filter(look => look.id !== lookId)
  //   setSavedLooks(updatedLooks)
  //   localStorage.setItem('savedLooks', JSON.stringify(updatedLooks))
  // }

  const formatDate = timestamp => {
    return new Date(timestamp).toLocaleDateString()
  }

  const renderLookPreview = (look, size = 150) => {
    const scale = size / 500;

    return (
      <div
        className='look-preview'
        style={{
          width: size,
          height: size
        }}
      >
        {look.items.map((item, index) => (
          <img
            key={index}
            src={item.image}
            alt={`Item ${index + 1}`}
            style={{
              position: 'absolute',
              left: `${(item.x * scale+15) / size * 100}%`,
              top: `${(item.y * scale) / size * 100}%`,
              width: `${item.width * scale / size * 100}%`,
              height: `${item.height * scale / size * 100}%`,
              zIndex: index + 1  // Add this line
            }}
          />
        ))}
      </div>
    )
  }

  const handleDragStart = (e, look) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(look))
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e, day) => {
    e.preventDefault()
    const look = JSON.parse(e.dataTransfer.getData('text/plain'))
    setWeeklyLooks(prev => {
      const newWeeklyLooks = { ...prev, [day]: look }
      localStorage.setItem('weeklyLooks', JSON.stringify(newWeeklyLooks))
      return newWeeklyLooks
    })
  }

  const renderWeeklyCalendar = () => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return (
      <Grid columns={7} divided>
        <Grid.Row>
          {days.map((day, index) => (
            <Grid.Column key={index} className="calendar-day">
              <div className="day-header">{day}</div>
              <div 
                className="day-content"
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, day)}
              >
                {weeklyLooks[day] && (
                  <div className="scheduled-look">
                    {renderLookPreview(weeklyLooks[day], 130)}
                    <p>{weeklyLooks[day].name}</p>
                  </div>
                )}
              </div>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    )
  }

  return (
    <div className='look-book'>
      <div className='look-icon-container'>
        <Icon
          name='home'
          size='large'
          color='teal'
          className='home-icon'
          onClick={handleHomeClick}
        />
        <Image
          src='https://res.cloudinary.com/sheyou/image/upload/v1645859101/t_shirt_23ad28188a.png'
          className='closet-icon'
          onClick={handleClosetClick}
          avatar
        />
      </div>
      <div className='header-container'>
        <h1>Look Book</h1>
        <Icon
          name={isEditMode ? "check" : "edit"}
          size='large'
          color='teal'
          className='edit-icon'
          onClick={handleEditClick}
        />
      </div>
      <div className='content-container'>
        <div className='card-container'>
          <Card.Group>
            {savedLooks.map((look) => (
              <Card 
                key={look.id} 
                className='look-card'
                draggable
                onDragStart={(e) => handleDragStart(e, look)}
              >
                <Card.Content>
                  <Card.Header>{look.name}</Card.Header>
                  <Card.Meta>Created on: {formatDate(look.id)}</Card.Meta>
                  {renderLookPreview(look)}
                  <Card.Description>
                    {look.items.length} items in this look
                  </Card.Description>
                  {isEditMode && (
                    <Icon
                      name='trash'
                      color='red'
                      className='delete-icon'
                      onClick={() => handleDeleteClick(look.id)}
                    />
                  )}
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        </div>
        <div className='calendar-container'>
          {renderWeeklyCalendar()}
        </div>
      </div>
    </div>
  )
}

export default LookBook
