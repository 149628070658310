import React from 'react';
import {
  Grid,
  Form,
  Button,
  Segment,
  Message,
  Header,
  Icon,
  Checkbox,
  Divider
} from 'semantic-ui-react';
// import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
// import Foot from '../foot/Foot';
import newOTP from 'otp-generators';
// import emailjs from 'emailjs-com';
// import { passwordStrength } from 'check-password-strength';
import Foot from '../foot page/Foot';
// var jwt = require('jsonwebtoken');

class SignUpLogInPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      email: '',
      password: '',
      passStrength: '',
      passWeak: false,
      sentEmail: '',
      loading: false,
      somethingWrong: false,
      rememberMe: false,
      loggedIn: false,
      buttonLoading: false
    };
    this.onHandleUsername = this.onHandleUsername.bind(this);
    this.onHandleEmail = this.onHandleEmail.bind(this);
    this.onHandlePassword = this.onHandlePassword.bind(this);
    this.onHandleSubmitSignUp = this.onHandleSubmitSignUp.bind(this);
    this.onHandleSubmitLogIn = this.onHandleSubmitLogIn.bind(this);
    this.onHandleRememberMe = this.onHandleRememberMe.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onHandleUsername(event) {
    this.setState({
      username: event.target.value
    });
  }

  onHandleEmail(event) {
    this.setState({
      email: event.target.value
    });
  }

  onHandlePassword(event) {
//     const password = event.target.value;
//     this.setState({
//       password: password,
//       passStrength: passwordStrength(password).value
    // });
  }

  onHandleSubmitSignUp(event) {
    const verificationCode = newOTP.generate(6, {
                    alphabets: false,
                    upperCase: false,
                    specialChar: false
                  });
                  console.log(verificationCode)
                
//     event.preventDefault();
//     const self = this;
//     const passStrength = self.state.passStrength;
//     if (passStrength === 'Weak' || passStrength === 'Too weak') {
//       this.setState({
//         passWeak: true
//       });
//     } else {
//       this.setState({
//         passWeak: false,
//         loading: true
//       });
//       axios
//         .post('https://sheyou-backend.herokuapp.com/auth/local/register', {
//           username: this.state.username,
//           email: this.state.email,
//           password: this.state.password,
//           confirmed: false
//         })
//         .then(function (response) {
//           self.setState({
//             loading: false,
//             sentEmail: true
//           });
//           const verificationCode = newOTP.generate(6, {
//             alphabets: false,
//             upperCase: false,
//             specialChar: false
//           });

//           const token = jwt.sign(
//             {
//               data: verificationCode
//             },
//             'sheyoufashion',
//             { expiresIn: '5min' }
//           );

//           emailjs
//             .send(
//               'service_orld5qb',
//               'template_c1h9qsr',
//               {
//                 email: self.state.email,
//                 reply_to: 'susanna.xinshu@gmail.com',
//                 name: self.state.username,
//                 verificationCode: verificationCode
//               },
//               'user_4rMSnDYBigUutDlR5RSib'
//             )
//             .then(
//               function (response) {
//                 self.setState({
//                   loading: false,
//                   sentEmail: true
//                 });
//                 localStorage.setItem('identifier', self.state.username);
//                 localStorage.setItem('verificationCode', token);
//                 self.props.history.push('/emailConfirmation');
//               },
//               function (error) {
//                 self.setState({
//                   loading: false,
//                   sentEmail: false,
//                   somethingWrong: 'FAILED...' + error.text
//                 });
//               }
//             );
//         })
//         .catch(error => {
//           self.setState({
//             loading: false,
//             sentEmail: false,
//             somethingWrong: error.response.data.message[0].messages[0].message
//           });
//         });
//     }
  }

  onHandleSubmitLogIn(event) {
//     event.preventDefault();
//     const self = this;
//     self.setState({
//       buttonLoading: true
//     });

//     axios
//       .post('https://sheyou-backend.herokuapp.com/auth/local', {
//         identifier: this.state.username,
//         password: this.state.password
//       })
//       .then(function (res) {
//         if (res.status === 200) {
//           const emailConfirmed = res.data.user.confirmed;
//           self.setState({
//             emailConfirmed: emailConfirmed
//           });

//           const token = res.data.jwt;
//           localStorage.setItem('emailConfirmed', emailConfirmed);
//           localStorage.setItem('token', token);
//           localStorage.setItem('identifier', self.state.username);
//           localStorage.setItem('password', self.state.password);
//           localStorage.setItem('rememberMe', self.state.rememberMe);

//           if (emailConfirmed) {
//             localStorage.setItem('login', 'true');
//             self.setState({
//               loggedIn: true,
//               buttonLoading: false
//             });
//             self.props.history.push('/logging');
//           } else {
//             self.setState({
//               buttonLoading: false,
//               somethingWrong: 'Email not confirmed'
//             });
//           }
//         }
//       })
//       .catch(error => {
//         self.setState({
//           buttonLoading: false,
//           somethingWrong: 'An error occurred:' + error.response.statusText
//         });
//       });
  }

  onHandleRememberMe(event) {
    this.setState({ rememberMe: !this.state.rememberMe });
  }

  render() {
    const windowWidth = window.innerWidth;

    const { sentEmail, passWeak, loading, somethingWrong, rememberMe, loggedIn, buttonLoading } = this.state;
    return (
        <Grid centered style={{ height: '100vh', width:'100vw', background: 'linear-gradient(to right, #a1ffce, #faffd1)' }}>
          <Segment placeholder>
            <Grid columns={2} centered>
              <Grid.Column style={{width:'400px'}}>
                <Header as='h2' color='teal' textAlign='center'>
                  Log In
                </Header>
                <Form>
                  <Form.Input
                    onChange={this.onHandleUsername}
                    icon='user'
                    iconPosition='left'
                    label='Username / email'
                    placeholder='Username / email'
                  />
                  <Form.Input
                    onChange={this.onHandlePassword}
                    icon='lock'
                    iconPosition='left'
                    label='Password'
                    type='password'
                  />
                  <Form.Field>
                    <Checkbox
                      checked={rememberMe}
                      label='Remember Me'
                      onClick={this.onHandleRememberMe}
                    />
                  </Form.Field>
                  <Button
                    loading={buttonLoading}
                    onClick={this.onHandleSubmitLogIn}
                    content='Log In'
                    color='orange'
                    fluid
                  />
                </Form>
                {loggedIn && (
                  <Message positive>
                    <Message.Header>Success</Message.Header>
                    <p>Now You Are Logged In</p>
                  </Message>
                )}
                {somethingWrong && (
                  <Message negative>
                    <Message.Header>Log In Failure</Message.Header>
                    <p>{somethingWrong}</p>
                  </Message>
                )}
              </Grid.Column>

              <Grid.Column verticalAlign='middle' style={{width:'400px'}}>
                <Header as='h2' color='teal' textAlign='center'>
                  Sign Up
                </Header>
                <Form>
                  <Form.Input
                    onChange={this.onHandleUsername}
                    label='Username'
                    placeholder='Username'
                  />
                  <Form.Input
                    onChange={this.onHandleEmail}
                    label='Email'
                    placeholder='Email'
                  />
                  <Form.Input
                    onChange={this.onHandlePassword}
                    label='Password'
                    placeholder='Password'
                    type='password'
                  />
                  <Button
                    onClick={this.onHandleSubmitSignUp}
                    type='submit'
                    fluid
                    size='large'
                    content='Sign Up'
                    color='orange'
                    disabled={this.state.username === '' || this.state.email === '' || this.state.password === ''}
                  />
                </Form>
                {passWeak && (
                  <Message
                    warning
                    header='Weak Password'
                    content='Please create a stronger password combination.'
                  />
                )}
                {sentEmail && (
                  <Message
                    success
                    header='Email Sent'
                    content='We Have Successfully Sent Confirmation, Please Check In Your Email, Expire in 5 Minutes'
                  />
                )}
                {somethingWrong && (
                  <Message
                    error
                    header='Register Failure'
                    content={'Something Wrong: ' + somethingWrong}
                  />
                )}
              </Grid.Column>
            </Grid>
            {windowWidth < 800 ? <Divider vertical>Or</Divider> : <></>} 
            
          </Segment>
        </Grid>
    );
  }
}

export default SignUpLogInPage;
